<template>
  <EuiDialog size="large" :open="open" @onClose="$emit('update:open', false); $emit('closeDialog')">
    <template v-slot:header>{{ $t('signatoriesDialog.title') }}</template>
    <EuiGrid>
      <EuiGridRow>
        <EuiGridColumn>
          <span v-if="selectedSign.length > 0" class="font-bold">{{ selectedSign.length }} signataires</span>
        </EuiGridColumn>
        <EuiGridColumn width="1/3">
          <EuiSearchBar :placeholder="$t('searchBar.placeholder')"
                        v-model.trim="searchInput"
                        @change="searchByNameOrEmail"
                        @search="searchByNameOrEmail"
                        @clear="onClear"
                        clearable
          />
        </EuiGridColumn>
      </EuiGridRow>
    </EuiGrid>
    <EuiAdvancedTable v-model="signatories" :selectedValue.sync="selectedSign">
      <template #tableHead>
        <!-- full name -->
        <EuiTableHead>
          {{ $t('signatoriesDialog.table.name') }}
          <EuiButton :selected="isFullNameMenuOpen"
                     id="menu-fullname"
                     class="eui-m-dropdownTableHead__anchor" iconOnly color="primary" size="small">
            <EuiIcon name="more_vert"/>
          </EuiButton>
          <EuiMenu ref="menu" @open="onMenuOpening('fullname')" @close="onMenuClosing('fullname')">
            <EuiMenuPanel anchor="menu-fullname" trigger="clickToToggle"
                          placement="bottom-start">
              <EuiCard>
                <EuiMenuItem @click:menu-item="onSort('fullname', 'asc')">
                  {{ $t('signatoriesDialog.sortFromAToZ')}}
                </EuiMenuItem>
                <EuiMenuItem @click:menu-item="onSort('fullname', 'desc')">
                  {{ $t('signatoriesDialog.sortFromZToA')}}
                </EuiMenuItem>
              </EuiCard>
            </EuiMenuPanel>
          </EuiMenu>
        </EuiTableHead>
        <EuiTableHead>
          {{ $t('signatoriesDialog.table.email') }}
          <EuiButton :selected="isEmailMenuOpen"
                     id="menu-email"
                     class="eui-m-dropdownTableHead__anchor" iconOnly color="primary" size="small">
            <EuiIcon name="more_vert"/>
          </EuiButton>
          <EuiMenu ref="menu" @open="onMenuOpening('email')" @close="onMenuClosing('email')">
            <EuiMenuPanel anchor="menu-email" trigger="clickToToggle"
                          placement="bottom-start">
              <EuiCard>
                <EuiMenuItem @click:menu-item="onSort('email', 'asc')">
                  {{ $t('signatoriesDialog.sortFromAToZ')}}
                </EuiMenuItem>
                <EuiMenuItem @click:menu-item="onSort('email', 'desc')">
                  {{ $t('signatoriesDialog.sortFromZToA')}}
                </EuiMenuItem>
              </EuiCard>
            </EuiMenuPanel>
          </EuiMenu>
        </EuiTableHead>
        <EuiTableHead>
          {{ $t('signatoriesDialog.table.group') }}
        </EuiTableHead>
      </template>
      <EuiATableRow selectable="multiple" autoSelect slot="tableRow" slot-scope="{ item }">
        <EuiATableCell>{{ item.fullname }}</EuiATableCell>
        <EuiATableCell>{{ item.email }}</EuiATableCell>
        <EuiATableCell>{{ item.group }}</EuiATableCell>
      </EuiATableRow>
      <template #tableEmptyState>
        <p>{{ $t('signatoriesDialog.create.signatory.noMatch') }}</p>
      </template>
    </EuiAdvancedTable>

    <!-- pagination -->
    <template v-slot:footer>
      <EuiPagination v-if="signatories.length"
                     class="mt-4"
                     :value="pagination.current"
                     @change="goToPage"
                     :paginationText="$t('pagination.text')"
                     :totalPages="pagination.total"/>
      <EuiButton color="primary" variant="raised" @click="addSignatories">{{ $t('button.add') }}
      </EuiButton>
    </template>

  </EuiDialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import mixin from '../../../mixins/compare';
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'AddSignatoriesDialog',
  mixins: [mixin],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    selected: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      signatories: [],
      searchInput: '',
      isFullNameMenuOpen: false,
      isEmailMenuOpen: false,
      searchParams: {
        offset: 0,
        limit: 20,
        orders: [
          {
            fieldName: 'fullname',
            orderBy: 'asc',
          },
        ],
        params: [],
      },
      selectedSign: [],
      socialSignersSearchParams: [{
        paramName: 'entityType',
        paramValue: 'socialSigner',
      }],
      tabItemShowed: 'users',
    };
  },
  watch: {
    open(value) {
      if (value) {
        this.selectedSign = cloneDeep(this.selected);
        this.searchInternalUsers();
      }
    },
  },
  computed: {
    ...mapState({
      pagination: (state) => state.ModuleEdocSign.settingsUsers.pagination,
    }),
  },
  methods: {
    ...mapActions({
      searchUsers: 'ModuleEdocSign/settingsUsers/search',
      searchGroup: 'ModuleEdocSign/settingsGroups/search',
    }),
    onMenuOpening(name) {
      if (name === 'fullname') {
        this.isFullNameMenuOpen = true;
      } else if (name === 'email') {
        this.isEmailMenuOpen = true;
      }
    },
    onMenuClosing(name) {
      if (name === 'fullname') {
        this.isFullNameMenuOpen = false;
      } else if (name === 'email') {
        this.isEmailMenuOpen = false;
      }
    },
    onSort(fieldName, orderBy) {
      this.searchParams.orders = [{ fieldName, orderBy }];
      this.searchInternalUsers();
    },
    onClear() {
      this.searchInput = '';
      this.searchParams.orders = [{ fieldName: 'fullname', orderBy: 'asc' }];
      this.searchParams.params = [{ paramName: 'hasSocialSignerTriggerPermission', paramValue: true }];
      this.searchInternalUsers();
    },
    searchByNameOrEmail() {
      this.searchParams.orders = [{ fieldName: 'fullname', orderBy: 'asc' }, { fieldName: 'email', orderBy: 'asc' }];
      this.searchParams.params = [
        { paramName: 'token', paramValue: this.searchInput },
        { paramName: 'hasSocialSignerTriggerPermission', paramValue: true }];
      this.searchInternalUsers();
    },
    searchInternalUsers() {
      const users = this.searchUsers(this.searchParams).then((response) => {
        if (response && response.accounts) {
          return response.accounts;
        }
      });
      const groups = this.searchGroup({ orders: [{ fieldName: 'name', orderBy: 'DESC' }], offset: 0, limit: 1000 }).then((response) => {
        if (response && response.groups) {
          return response.groups;
        }
      });
      return Promise.all([users, groups]).then((values) => {
        for (let i = 0; i < values[0].length; i += 1) {
          const found = values[1].find((element) => element.id === values[0][i].userGroupId);
          if (found) {
            values[0][i].group = values[1].find((element) => element.id === values[0][i].userGroupId).name;
          }
        }
        this.signatories = values[0];
      });
    },
    addSignatories() {
      this.$emit('change', JSON.parse(JSON.stringify(this.selectedSign)));
      this.$emit('update:open', false);
      this.$emit('closeDialog');
    },
    goToPage(page) {
      this.searchParams.offset = (page - 1) * this.searchParams.limit;
      this.searchInternalUsers(this.searchParams);
    },
  },
};
</script>
